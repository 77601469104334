import React from 'react'
import axios from 'axios'
import { Link } from 'gatsby';

export default class ServicesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
    }
  }

  componentDidMount() {
    try {
      const WOW = require('wowjs')
      const wow = new WOW.WOW()
      wow.init()
    } catch (e) {
      console.error(e)
    }

    axios
      .get(`${process.env.API_URL}/umbraco/api/service/getservices`)
      .then(result => {
        this.setState({
          data: result.data,
        })
      })
      .catch(error => { })
  }

  render() {
    return (
      <div>
        <section className="section-top">
          <div className="container">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="section-top-title">
                <h2>Consultas</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12" />
          </div>
        </section>
        <section className="our_activites">
          <div className="container">
            <div className="row text-center">
              {this.state.data.map((service, idx) => {
                return (                 
                  <div key={idx} className="col-md-4 col-sm-12">
                    <div className="single_activites card">
                      <img src={`${process.env.API_URL}${service.Photo}`} />
                      <h4 className="text">{service.Name}</h4>
                      <Link to={`/service-detail/${service.Url}`}>
                        <span className="btn-contact-bg solid">Ver Mais</span>
                      </Link>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </div>
    )
  }
}
